<template>
  <div>
    <v-sheet >

          <related-modules></related-modules>

    </v-sheet>

  </div>
</template>

<script>

import RelatedModules from "@/components/RelatedModules.vue";

export default {
  name: "Home3",
  props: [],
  components: {RelatedModules},
  data() {
    return {}
  },
  methods: {},
  mounted() {

  }
}
</script>

<style type="text/scss" lang="scss" scoped>

</style>