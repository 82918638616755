<template>
  <v-app app>
    <v-sheet class="mx-2" >
      <v-sheet  class="custom-container" style="width: 100%" >
          <v-sheet class="transparent  mx-4 v-sheet_div_style align-content-center" height="100%" >
            <div class="font-weight-bold" >LinkedOmics Suite</div>
            <div style="font-size: 28px;color: #000000; z-index: 99999!important;">A comprehensive suite of web tools for cancer omics data analysis and exploration</div>
          </v-sheet>

      </v-sheet>
      <v-tabs
          background-color="#3AA5c8"
          dark
          height="20px"
          class="font-weight-bold"
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
<!--        <v-tab to="/">-->
<!--          Home-->
<!--        </v-tab>-->
<!--        <v-tab to="/document">-->
<!--          Documentation-->
<!--        </v-tab>-->

        <v-spacer></v-spacer>

      </v-tabs>
      <main>
        <router-view></router-view>
      </main>


      <footer class="mt-4 mr-3 text-right">
        © 2018-2024
        <a href="https://www.zhang-lab.org/" target="_blank" class="text-decoration-none"> Zhang Lab</a>
      </footer>

    </v-sheet>



  </v-app>
</template>

<script>
// require('@/assets/background1.png')
require('@/assets/background2.jpg')

export default {
  name: 'App',

  data: () => ({

  })}

// };
</script>

<style>
.transparent {
  background-color: transparent !important;
}
/*
//.custom-container {
//  width: 100%;
//  min-height: 200px;
//  background-size: cover;
//  background-color: rgba(255, 255, 255, 0.5);
//  background-image: url('~@/assets/background1.png');
//}
 */
.custom-container {
  position: relative;
  width: 100%;
  height: 230px;
  overflow: hidden;
}


.custom-container::after {
  content: '';
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3)), url('~@/assets/background2.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  opacity: 0.6;
  z-index: 1;
}


.v-sheet_div_style {
  position: relative;
  z-index: 99999;  /* 确保这个值高于背景层的 z-index */
  font-size: 32px;  /* 可以根据实际需求调整字体大小 */
  color: #000000;

}



</style>