<template>
      <v-card  class="mt-8  mx-2 pa-0">
        <v-card-title>
          Documentation for LinkedOmics Suite
        </v-card-title>
        <v-sheet class="ma-4" >
          <div>
            <a class="font-weight-bold" href="https://www.linkedomics.org/" target="_blank" style=" text-decoration: none!important;">  All-rounder (LinkedOmics): </a> <a href="https://www.linkedomics.org/login.php#manual">Help</a>
            <br><br>
            <a class="font-weight-bold" href="https://kb.linkedomics.org/" target="_blank" style=" text-decoration: none!important;">Knowledge Base (LinkedOmicsKB):  </a>
            <a href="https://kb.linkedomics.org/manual">Help</a>
            <br><br>
            <a class="font-weight-bold" href="https://targets.linkedomics.org/" target="_blank" style="text-decoration: none!important;">Targets (Target Explorer): </a>
            <a href="https://targets.linkedomics.org/doc.html">Help</a>
            <br><br>
            <a class="font-weight-bold" href="http://trials.linkedomics.org/" target="_blank" style="text-decoration: none!important;">Trial Analyzer (ClinicalOmicsDB): </a>
            <a href="http://trials.linkedomics.org/about/">Help</a>

          </div>
          <br>
        </v-sheet>
      </v-card>

</template>
<script>
export default {
  name: 'documentation',

  data: () => ({
    //
  }),
};
</script>
<style scoped>

</style>