<template>
  <div>
    <v-sheet outlined rounded>
      <v-sheet class="mt-6 mb-2 mx-4">
        <v-row>
          <v-col cols="12">
            <v-sheet class="font-weight-bold" style="font-size: 1.2rem">Current Tools:
              <v-chip

                  v-for="(tool, index) in timelineItems"
                  :key="index"
                  outlined
                  :color="tool.color"
                  class="mx-1 my-1"
                  @click="scrollToTimelineItem(index)"
              >
                <!-- Add icons conditionally for the last chip -->
                <template v-if="index === timelineItems.length - 1">
                  <v-icon>mdi-alert-decagram</v-icon>
                  <v-icon large>mdi-new-box</v-icon>
                </template>
                {{ tool.name }}
              </v-chip>

<!--              <v-chip-->
<!--                  v-for="(tool, index) in timelineItems"-->
<!--                  :key="index"-->
<!--                  outlined-->
<!--                  :color="tool.color"-->
<!--                  class="mx-1"-->
<!--                  @click="scrollToTimelineItem(index)"-->
<!--              >-->
<!--                {{ tool.name }}-->
<!--              </v-chip>-->
<!--              <v-chip outlined color="#009688" class="mx-1" @click="scrollToTimelineItem(0)">All-rounder (LinkedOmics)</v-chip>-->
<!--              <v-chip outlined color="#64B5F6" class="mx-1" @click="scrollToTimelineItem(1)"  >Knowledge Base (LinkedOmicsKB)</v-chip>-->
<!--              <v-chip outlined color="#F06292" class="mx-1" @click="scrollToTimelineItem(2)" >Target Explorer (Targets)</v-chip>-->
<!--              <v-chip outlined color="#66BB6A" class="mx-1" @click="scrollToTimelineItem(3)" >Trial Analyzer (ClinicalOmicsDB)</v-chip>-->
<!--              <v-chip outlined color="#B71C1C" class="mx-1" @click="scrollToTimelineItem(4)" >-->
<!--                <v-icon>mdi-alert-decagram</v-icon>-->
<!--                <v-icon large>mdi-new-box</v-icon> &nbsp;Functional Network (FunMap)-->
<!--              </v-chip>-->
            </v-sheet>

          </v-col>
        </v-row>

      </v-sheet>
      <v-sheet class="mx-4">
        <v-icon color="teal darken-1" dense size="middle">mdi-open-in-new</v-icon>
        Click the tool to access the corresponding web portal
      </v-sheet>
      <v-sheet class="mx-4 my-2">
        <v-timeline dense align-top>
          <v-timeline-item
              v-for="(item, index) in timelineItems"
              :key="index"
              :color="item.color"
              small
              :id="`timeline-item-${index}`"
          >
            <v-sheet outlined rounded>
              <v-row class="mx-1 my-1">

                <v-col cols="12" md="4" lg="4" xl="4" sm="12" xs="12"
                ><a :href="item.link" target="_blank" class="no-decoration"
                    style="font-size: 1.2rem;font-weight: bold ">
                  <v-icon color="teal darken-1" dense size="middle">mdi-open-in-new</v-icon>
                  {{ item.name }}</a>
                  <br>
                  <a v-if="item.help_link" :href="item.help_link">Help</a>
                </v-col>
                <v-col cols="12" md="8" lg="8" xl="8" sm="12" xs="12">
                  <v-sheet style="color: black">
                    <div><strong>Description:</strong> {{ item.description }}</div>
                    <v-row align="center" no-gutters>
<!--                      <v-col cols="auto">-->
                        <strong>Key Features:</strong>
<!--                      </v-col>-->
<!--                      <v-col cols="12">-->
<!--                        <v-chip-group row class="flex-wrap">-->
                          <v-chip
                              v-for="(feature, index) in item.key_feature.split(';')"
                              :key="index"
                              color="dark grey"
                              outlined
                              label
                              class="mx-1 my-1"
                          >
                            {{ feature.trim() }}
                          </v-chip>
<!--                        </v-chip-group>-->
<!--                      </v-col>-->
                    </v-row>

                    <div>
                      <strong>Reference:</strong> {{ item.related_paper }}
                      <div v-if="item.paper_ref" style="word-wrap: break-word; overflow-wrap: break-word;">
                        DOI: <a :href="item.paper_ref" target="_blank">
                        {{ item.paper_ref }}
                      </a>
                      </div>
                    </div>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-sheet>

          </v-timeline-item>
        </v-timeline>
      </v-sheet>
    </v-sheet>
<!--    <v-sheet outlined rounded>-->
<!--      <v-sheet class="mt-6 mb-2 mx-4">-->
<!--        <v-row>-->
<!--          <v-col cols="12">-->
<!--            <v-sheet class="font-weight-bold" style="font-size: 1.2rem">Current Tools:-->
<!--              <v-chip outlined color="#009688" class="mx-1">All-rounder (LinkedOmics)</v-chip>-->
<!--              <v-chip outlined color="#64B5F6" class="mx-1">Knowledge Base (LinkedOmicsKB)</v-chip>-->
<!--              <v-chip outlined color="#F06292" class="mx-1">Target Explorer (Targets)</v-chip>-->
<!--              <v-chip outlined color="#66BB6A" class="mx-1">Trial Analyzer (ClinicalOmicsDB)</v-chip>-->
<!--              <v-chip outlined color="#B71C1C" class="mx-1">-->
<!--                <v-icon>mdi-alert-decagram</v-icon>-->
<!--                <v-icon large>mdi-new-box</v-icon> &nbsp;Functional Network (FunMap)-->
<!--              </v-chip>-->
<!--            </v-sheet>-->

<!--          </v-col>-->
<!--        </v-row>-->

<!--      </v-sheet>-->
<!--      <v-sheet class="mx-4">-->
<!--        <v-icon color="teal darken-1" dense size="middle">mdi-open-in-new</v-icon>-->
<!--        Click the tool to access the corresponding web portal-->
<!--      </v-sheet>-->
<!--      <v-sheet class="mx-4 my-2">-->
<!--        <v-timeline dense align-top>-->
<!--          <v-timeline-item-->
<!--              v-for="(item, index) in timelineItems"-->
<!--              :key="index"-->
<!--              :color="item.color"-->
<!--              small-->
<!--          >-->
<!--            <v-sheet outlined rounded>-->
<!--              <v-row class="mx-1 my-1">-->

<!--                <v-col cols="12" md="4" lg="4" xl="4" sm="12" xs="12"-->
<!--                ><a :href="item.link" target="_blank" class="no-decoration"-->
<!--                    style="font-size: 1.2rem;font-weight: bold ">-->
<!--                  <v-icon color="teal darken-1" dense size="middle">mdi-open-in-new</v-icon>-->
<!--                  {{ item.name }}</a>-->
<!--                  <br>-->
<!--                  <a v-if="item.help_link" :href="item.help_link">Help</a>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="8" lg="8" xl="8" sm="12" xs="12">-->
<!--                  <v-sheet style="color: black">-->
<!--                    <div><strong>Description:</strong> {{ item.description }}</div>-->
<!--                    <v-row align="center">-->
<!--                      <v-col cols="auto">-->
<!--                        <strong>Key Features:</strong>-->
<!--                      </v-col>-->
<!--                      <v-col>-->
<!--                        <v-chip-group-->
<!--                            row-->
<!--                        >-->
<!--                          <v-chip-->
<!--                              v-for="(feature, index) in item.key_feature.split(';')"-->
<!--                              :key="index"-->
<!--                              color="dark grey"-->
<!--                              outlined-->
<!--                              label-->
<!--                          >-->
<!--                            {{ feature.trim() }}-->
<!--                          </v-chip>-->
<!--                        </v-chip-group>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                    &lt;!&ndash;                   <div><strong>Key Features:</strong> {{ item.key_feature }}</div>&ndash;&gt;-->
<!--                    <div>-->
<!--                      <strong>Reference:</strong> {{ item.related_paper }}-->
<!--                      <span v-if="item.paper_ref">-->
<!--                        DOI: <a :href="item.paper_ref" target="_blank">-->
<!--                        {{ item.paper_ref }}-->
<!--                      </a>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </v-sheet>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-sheet>-->

<!--          </v-timeline-item>-->
<!--        </v-timeline>-->
<!--      </v-sheet>-->

<!--      &lt;!&ndash;      <v-sheet class="ma-4">&ndash;&gt;-->
<!--      &lt;!&ndash;        <v-timeline dense align-top>&ndash;&gt;-->
<!--      &lt;!&ndash;          <v-timeline-item&ndash;&gt;-->
<!--      &lt;!&ndash;              v-for="(item, index) in timelineItems"&ndash;&gt;-->
<!--      &lt;!&ndash;              :key="index"&ndash;&gt;-->
<!--      &lt;!&ndash;              :color="item.color"&ndash;&gt;-->
<!--      &lt;!&ndash;              small&ndash;&gt;-->
<!--      &lt;!&ndash;          >&ndash;&gt;-->
<!--      &lt;!&ndash;            <v-hover v-slot="{ hover }">&ndash;&gt;-->
<!--      &lt;!&ndash;              <v-card&ndash;&gt;-->
<!--      &lt;!&ndash;                  :elevation="hover ? 6 : 2"&ndash;&gt;-->
<!--      &lt;!&ndash;                  class="pa-3"&ndash;&gt;-->
<!--      &lt;!&ndash;                  style="cursor: pointer; text-decoration: none; color: inherit;"&ndash;&gt;-->
<!--      &lt;!&ndash;              >&ndash;&gt;-->
<!--      &lt;!&ndash;                <v-card-title class="text-h6">&ndash;&gt;-->
<!--      &lt;!&ndash;                  <a :href="item.link" target="_blank" class="no-decoration">&ndash;&gt;-->
<!--      &lt;!&ndash;                    <v-icon color="teal darken-1" dense size="middle">mdi-open-in-new</v-icon>&nbsp;&nbsp;{{&ndash;&gt;-->
<!--      &lt;!&ndash;                      item.name&ndash;&gt;-->
<!--      &lt;!&ndash;                    }}</a>&ndash;&gt;-->
<!--      &lt;!&ndash;                </v-card-title>&ndash;&gt;-->
<!--      &lt;!&ndash;                <v-card-text style="color: black">&ndash;&gt;-->
<!--      &lt;!&ndash;                  <div><strong>Description:</strong> {{ item.description }}</div>&ndash;&gt;-->

<!--      &lt;!&ndash;                  <div><strong>Key Features:</strong> {{ item.key_feature }}</div>&ndash;&gt;-->
<!--      &lt;!&ndash;                  <div><strong>Reference:</strong> {{ item.related_paper }} doi: <a :href="item.paper_ref"&ndash;&gt;-->
<!--      &lt;!&ndash;                                                                                        target="_blank">{{&ndash;&gt;-->
<!--      &lt;!&ndash;                      item.paper_ref&ndash;&gt;-->
<!--      &lt;!&ndash;                    }}</a></div>&ndash;&gt;-->
<!--      &lt;!&ndash;                </v-card-text>&ndash;&gt;-->
<!--      &lt;!&ndash;              </v-card>&ndash;&gt;-->
<!--      &lt;!&ndash;            </v-hover>&ndash;&gt;-->
<!--      &lt;!&ndash;          </v-timeline-item>&ndash;&gt;-->
<!--      &lt;!&ndash;        </v-timeline>&ndash;&gt;-->
<!--      &lt;!&ndash;      </v-sheet>&ndash;&gt;-->

<!--    </v-sheet>-->
    &nbsp;
  </div>
</template>

<script>
export default {
  name: "RelatedModules",
  props: [],
  components: {},
  data() {
    return {
      elevation: 2,
      timelineItems: [
        {
          name: "All-rounder (LinkedOmics)",
          color: "#009688",
          link: "https://www.linkedomics.org/login.php",
          description: "Flexible, user-driven analysis of multi-omics data from TCGA, CPTAC, and other cohort studies.",
          key_feature: "LinkFinder; LinkCompare; LinkInterpreter",
          related_paper: "Vasaikar, Suhas V., et al. Nucleic Acids Research (2018).",
          paper_ref: "https://doi.org/10.1093/nar/gkx1090",
          help_link: "https://www.linkedomics.org/login.php#manual"
        },
        {
          name: "Knowledge Base (LinkedOmicsKB)",
          color: "#64B5F6",
          link: "https://kb.linkedomics.org/",
          description: "Direct access to results and visualizations from a pan-cancer analysis of CPTAC proteogenomic data.",
          key_feature: "Search Gene; Search Phenotype; Search Mutation; Compare Tumor and Normal",
          related_paper: "Liao, Yuxing, et al. Cell Systems (2023).",
          paper_ref: "https://doi.org/10.1016/j.cels.2023.07.007",
          help_link: "https://kb.linkedomics.org/manual"
        },
        {
          name: "Target Explorer (Targets)",
          color: "#F06292",
          link: "https://targets.linkedomics.org",
          description: "Prioritization of therapeutic targets by integrating tumor proteogenomic data with genetic perturbation data from cell lines.",
          key_feature: "Over-expressed and hyper-activated dependencies; Synthetic lethal dependencies; Neoantigens and tumor-associated antigens",
          related_paper: "Savage, Sara R., et al. Cell (2024).",
          paper_ref: "https://doi.org/10.1016/j.cell.2024.05.039",
          help_link: "https://targets.linkedomics.org/doc.html"
        },
        {
          name: "Trial Analyzer (ClinicalOmicsDB)",
          color: "#66BB6A",
          link: "http://trials.linkedomics.org/",
          description: "Identification of candidate biomarkers through the analysis of omics data from oncology trials.",
          key_feature: "Study-Centric Analysis; Treatment-Centric Analysis; Gene-Centric Analysis",
          related_paper: "Moon, Chang In, et al. Nucleic Acids Research (2024).",
          paper_ref: "https://doi.org/10.1093/nar/gkad871",
          help_link: "http://trials.linkedomics.org/about/"
        },
        {
          name: "Functional Network (FunMap)",
          color: "#B71C1C",
          link: "https://bzhanglab.github.io/funmap/",
          description: "Visualize and explore a proteogenomics data-driven, machine learned functional map of human cancer.",
          key_feature: "Gene Neighborhoods; Dense Modules; Hierarchical Organization",
          related_paper: "Shi, Zhiao, et al. In Press",
          // paper_ref: "https://doi.org/10.1093/nar/gkad871",
          // help_link: "http://trials.linkedomics.org/about/"
        },
      ]
    }
  },
  methods: {
    scrollToTimelineItem(index) {
      const element = document.getElementById(`timeline-item-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  mounted() {

  }
}
</script>

<style type="text/scss" lang="scss" scoped>
.no-decoration {
  text-decoration: none;
  color: inherit;
}
</style>