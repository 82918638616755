import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Documentation from "@/views/Documentation.vue";

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
 //  {
 //    path: '/document',
 //    name: 'documentation',
 //    component: Documentation
 // }
]

const router = new VueRouter({
  routes:routes
})
// const router = new VueRouter({
//   mode: 'history',  // 添加此行来启用 history 模式
//   routes: routes
// })

export default router
